<template>
    <CompTable ref="comp_table" title="栏目更新监测结果" :searchData="{ overdue: 'true' }" :columns="columns" :table-api="tableApi">
        <template #search="evt">
            <Select v-model="evt.search.overdue" placeholder="类型">
                <Option value="true">逾期</Option>
                <Option value="false">全部</Option>
            </Select>
            <Select v-model="evt.search.orgCat" placeholder="机构类型">
                <Option value="1">市级示范社区</Option>
                <Option value="2">区级示范社区</Option>
                <Option value="3">街镇级示范社区</Option>
            </Select>
        </template>
    </CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Route from "@/views/communitymobilization/entity/Route"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            tableApi: `/gateway/api/symanage/pc/columnAlarm/listAllOrgColumnOverdue?dimensionId=${this.$core.getUrlParam("dimensionId")}&overdue=true`,
            columns: [
                {
                    title: "名称",
                    key: "orgName",
                },
                {
                    title: "总栏目数",
                    key: "columnNum",
                },
                {
                    title: "逾期天数更新",
                    key: "overdueDay",
                },
                {
                    title: "逾期更新栏目数",
                    key: "overdueColumn",
                    style: {
                        color: "red",
                    },
                    click: p => {
                        Route.jump("/columnreminderdetectioncolumndetail", {
                            dataScopeId: p.row.orgCode,
                            orgName: p.row.orgName,
                        })
                    },
                },
                {
                    title: "排序",
                    render: (h, p) => p.index + 1,
                },
            ],
        }
    },
}
</script>

<style></style>
